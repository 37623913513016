import React, { useState, useEffect } from "react";
import styled from "styled-components";

import ContentAnimation from "./ContentAnimation";
import { graphql, useStaticQuery } from "gatsby";
import debounce from "lodash/debounce";
import { mediaMedium } from "./styles/variables";
import SponsorContent from "./SponsorContent";
import Container from "./styles/Container";
import { useInView } from "react-intersection-observer";

const Mobile = styled.div`
  @media (${mediaMedium}) {
    display: none;
  }
`;

const Desktop = styled(Container)`
  display: none;
  position: relative;

  @media (${mediaMedium}) {
    display: block;
  }
`;

const Trigger = styled.div`
  position: absolute;
  width: 100%;
  height: 50vw;
  top: 0;
  transform: translateY(-70%);
  left: 0;
  pointer-events: none;
`;

const Threshold = [0, 0.5, 1];

const SponsorContentAnimation = () => {
  const images = useStaticQuery(graphql`
    query {
      desktopOne: file(relativePath: { eq: "33-sponsored-content-1.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      desktopTwo: file(relativePath: { eq: "33-sponsored-content-2.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      desktopThree: file(relativePath: { eq: "33-sponsored-content-3.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      contentOne: file(relativePath: { eq: "44-commerce-content-1.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      contentTwo: file(relativePath: { eq: "44-commerce-content-2.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  const [size, setSize] = useState(
    Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  );

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setSize(
        Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        )
      );
    }, 300);

    window.addEventListener("resize", debouncedHandleResize, { passive: true });

    return () => {
      window.removeEventListener("resize", debouncedHandleResize, {
        passive: true
      });
    };
  });

  const { ref, inView } = useInView({
    threshold: Threshold,
    delay: 30,
    triggerOnce: true
  });

  return (
    <>
      {size < 830 && (
        <Mobile>
          <ContentAnimation images={images} />
        </Mobile>
      )}
      {size > 829 && (
        <Desktop>
          <Trigger ref={ref} />
          <SponsorContent inView={inView} images={images} />
        </Desktop>
      )}
    </>
  );
};

export default SponsorContentAnimation;
