import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import HorizontalPhonesAnimation from "./HorizontalPhonesAnimation";

const StackEmailAnimation = ({ theme, forceActive }) => {
  const images = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "32-email-3.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      two: file(relativePath: { eq: "32-email-2.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      three: file(relativePath: { eq: "32-email-1.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <HorizontalPhonesAnimation
      forceActive={forceActive}
      images={images}
      theme={theme}
    />
  );
};

export default StackEmailAnimation;
