import React from "react";
import { Link } from "gatsby";
import StackAdsBrands from "../brands/StackAdsBrands";
import HeroSectionExtraHeading from "../HeroSectionExtraHeading";
import CTALink from "../styles/CTALink";
import HeroSection from "../HeroSection";
import HeroBlueCTA from "../HeroBlueCTA";
import MoreWaysToEarn from "../MoreWaysToEarn";
import { FadeInRightSmall } from "../styles/animations";
import SponsorContentAnimation from "../SponsorContentAnimation";
import StackEmailAnimation from "../StackEmailAnimation";

// Images
import stackBarDesktop from "../../images/30-stackbar-desktop.png";
import stackBarMobile from "../../images/30-stackbar-mobile.png";
import nativeAdDesktop from "../../images/31-native-ads-desktop.png";
import nativeAdMobile from "../../images/31-native-ads-mobile.png";

const stackBarImages = {
  desktop: {
    src: stackBarDesktop,
    width: 1498,
    height: 880
  },
  mobile: {
    src: stackBarMobile,
    width: 375,
    height: 503
  }
};

const nativeAdsImages = {
  desktop: {
    src: nativeAdDesktop,
    width: 1499,
    height: 881
  },
  mobile: {
    src: nativeAdMobile,
    width: 375,
    height: 504
  }
};

const PublishersStackMediaDefer = () => {
  return (
    <>
      <StackAdsBrands />

      <HeroSectionExtraHeading
        heading="Our Offerings"
        top="01"
        title="Sponsored Content"
        theme={{ paddingMedia: "91px 0 0" }}
        AnimationComponent={SponsorContentAnimation}
      >
        Get original sponsored articles written by Stack’s{" "}
        <CTALink
          variant="white"
          bold
          underlined
          display="inline-block"
          attrs={{
            to: "/publishers/content-studio/"
          }}
        >
          Content Studio
        </CTALink>{" "}
        in your site’s voice that drive directly to the brand sites. You earn
        with every click.
      </HeroSectionExtraHeading>

      <HeroSection
        title="Email Newsletters"
        top="02"
        theme={{
          paddingDesktop: "160px 0 240px"
        }}
        reverse={true}
        displayComponent={<StackEmailAnimation forceActive={true} />}
      >
        Have a robust subscriber list? Dedicate an entire email to one of our
        top-notch brand partners, or add a brief mention to earn with every
        send.
      </HeroSection>

      <HeroSection
        title="Native Ad Units"
        top="03"
        images={nativeAdsImages}
        alt={true}
        animation={FadeInRightSmall}
        theme={{
          imageTop: "64px",
          paddingDesktop: "200px 0",
          overflowWidth: "1000"
        }}
      >
        Unlike a traditional ad, our exclusive native ad units are integrated
        organically into the reader experience — so you’ll never lose out to ad
        blockers.
      </HeroSection>

      <HeroSection
        title="STACKBAR"
        top="04"
        theme={{ imageLeft: "42.3%" }}
        images={stackBarImages}
      >
        Easily integrate StackBar into the top of your site to drive clicks
        without disrupting the user experience.
      </HeroSection>  

      <HeroBlueCTA
        title="Let’s create"
        outline="Together"
        description="It’s easy—just tell us a little bit about your site and audience and you’ll be on your way."
        linkLabel="Get Started"
        link={{ to: "/contact/?smopt=publisher", state: { from: "publisher" }, as: Link }}
      />

      <MoreWaysToEarn />
    </>
  );
};

export default PublishersStackMediaDefer;
