import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Image from "../Image";
import Container from "../styles/Container";
import { black1 } from "../styles/Colors";
import { mediaDesktop, mediaMedium } from "../styles/variables";
import AnimationWrapper from "../AnimationWrapper";
import { MoveInBottom } from "../styles/animations";

const Background = styled.div`
  background-color: ${black1};
  padding: 96px 0;

  @media (${mediaMedium}) {
    padding: 160px 0;
  }
`;

const Item = styled.div`
  grid-column: span 1;
  order: ${(props) => props.order ?? 1};

  &:last-of-type {
    display: none;
  }

  @media (${mediaMedium}) {
    grid-column: span 12;
    order: 1 !important;

    &:last-of-type {
      display: block;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  grid-row-gap: 58px;
  grid-column-gap: 58px;
  grid-template-columns: repeat(3, 1fr);

  @media (${mediaMedium}) {
    grid-row-gap: 98px;
    grid-template-columns: repeat(60, 1fr);
  }

  .gatsby-image-wrapper {
    margin: 0 auto;
    overflow: visible !important;
  }

  @media (max-width: 829px) {
    ${Item}:nth-child(3n) {
      .gatsby-image-wrapper {
        margin-right: 0;
      }
    }

    ${Item}:nth-child(3n-2) {
      .gatsby-image-wrapper {
        margin-left: 0;
      }
    }

    ${Item}:nth-child(10) {
      .gatsby-image-wrapper {
        max-width: 70px !important;
      }
    }

    ${Item}:nth-child(22) {
      .gatsby-image-wrapper {
        max-width: 60px !important;
      }
    }

    ${Item}:nth-child(20) {
      .gatsby-image-wrapper {
        max-width: 50px !important;
      }
    }

    ${Item}:nth-child(3) {
      .gatsby-image-wrapper {
        max-width: 60px !important;
      }
    }

    ${Item}:nth-child(6) {
      .gatsby-image-wrapper {
        max-width: 46px !important;
      }
    }
  }

  @media (${mediaMedium}) {
    grid-column-gap: 6px;
    padding: 0 16px;

    ${Item} {
      width: 85%;
    }

    ${Item}:nth-child(5n+1) {
      text-align: left;

      .gatsby-image-wrapper {
        margin: 0 auto 0 0;
      }
    }

    ${Item}:nth-child(5n) {
      text-align: right;

      .gatsby-image-wrapper {
        margin: 0 0 0 auto;
      }
    }

    ${Item}:nth-child(6),
    ${Item}:nth-child(10),
    ${Item}:nth-child(11),
    ${Item}:nth-child(15),
    ${Item}:nth-child(20) {
      grid-column: span 8;
    }

    ${Item}:nth-child(n + 8):nth-child(-n + 9),
    ${Item}:nth-child(n + 13):nth-child(-n + 14),
    ${Item}:nth-child(19) {
      grid-column: span 16;
    }
  }

  @media (${mediaDesktop}) {
    grid-column-gap: 16px;

    ${Item} {
      width: 100%;
    }
  }
`;

const StackMediaBrandsSection = ({ data }) => {
  return (
    <Background>
      <Container>
        <Grid>
          {data &&
            Object.keys(data).map((item, key) => (
              <Item key={item} order={key + 1}>
                <AnimationWrapper component={MoveInBottom}>
                  <Image fluid={data[item].childImageSharp.fluid} />
                </AnimationWrapper>
              </Item>
            ))}
        </Grid>
      </Container>
    </Background>
  );
};

const StackMediaBrands = () => {
  const data = useStaticQuery(graphql`
    query {
      hellosign: file(relativePath: { eq: "68-hellosign.png" }) {
        childImageSharp {
          fluid(maxWidth: 180, maxHeight: 26, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      godaddy: file(relativePath: { eq: "68-godaddy.png" }) {
        childImageSharp {
          fluid(maxWidth: 158, maxHeight: 33, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      salesforce: file(relativePath: { eq: "68-salesforce.png" }) {
        childImageSharp {
          fluid(maxWidth: 102, maxHeight: 72, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      nationBuilder: file(relativePath: { eq: "68-nation-builder.png" }) {
        childImageSharp {
          fluid(maxWidth: 209, maxHeight: 54, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      monday: file(relativePath: { eq: "68-monday.png" }) {
        childImageSharp {
          fluid(maxWidth: 144, maxHeight: 32, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      mailchimp: file(relativePath: { eq: "68-mailchimp.png" }) {
        childImageSharp {
          fluid(maxWidth: 70, maxHeight: 89, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      lolaCom: file(relativePath: { eq: "68-lola-com.png" }) {
        childImageSharp {
          fluid(maxWidth: 140, maxHeight: 28, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      spectrm: file(relativePath: { eq: "68-spectrm.png" }) {
        childImageSharp {
          fluid(maxWidth: 180, maxHeight: 24, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      snapInc: file(relativePath: { eq: "68-snap-inc.png" }) {
        childImageSharp {
          fluid(maxWidth: 145, maxHeight: 35, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      wix: file(relativePath: { eq: "68-wix.png" }) {
        childImageSharp {
          fluid(maxWidth: 82, maxHeight: 32, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      babbel: file(relativePath: { eq: "68-babbel.png" }) {
        childImageSharp {
          fluid(maxWidth: 110, maxHeight: 24, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      seedInvest: file(relativePath: { eq: "68-seed-invest.png" }) {
        childImageSharp {
          fluid(maxWidth: 110, maxHeight: 24, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      lemonade: file(relativePath: { eq: "68-lemonade.png" }) {
        childImageSharp {
          fluid(maxWidth: 140, maxHeight: 30, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      diversyFund: file(relativePath: { eq: "68-diversy-fund.png" }) {
        childImageSharp {
          fluid(maxWidth: 272, maxHeight: 39, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      trueBill: file(relativePath: { eq: "68-truebill.png" }) {
        childImageSharp {
          fluid(maxWidth: 127, maxHeight: 30, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      zebra: file(relativePath: { eq: "68-zebra.png" }) {
        childImageSharp {
          fluid(maxWidth: 133, maxHeight: 43, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      aspiration: file(relativePath: { eq: "68-aspiration.png" }) {
        childImageSharp {
          fluid(maxWidth: 133, maxHeight: 32, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      arcadiaPower: file(relativePath: { eq: "68-arcadia-power.png" }) {
        childImageSharp {
          fluid(maxWidth: 121, maxHeight: 52, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      pennyHoarder: file(relativePath: { eq: "68-penny-hoarder.png" }) {
        childImageSharp {
          fluid(maxWidth: 228, maxHeight: 23, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      hp: file(relativePath: { eq: "29-hp.png" }) {
        childImageSharp {
          fluid(maxWidth: 58, maxHeight: 56, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      shutterstock: file(relativePath: { eq: "68-xbox-gamepass.png" }) {
        childImageSharp {
          fluid(maxWidth: 164, maxHeight: 41, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      wineAccess: file(relativePath: { eq: "68-wine-access.png" }) {
        childImageSharp {
          fluid(maxWidth: 72, maxHeight: 75, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      trade: file(relativePath: { eq: "68-trade.png" }) {
        childImageSharp {
          fluid(maxWidth: 99, maxHeight: 32, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      tikTok: file(relativePath: { eq: "68-tiktok.png" }) {
        childImageSharp {
          fluid(maxWidth: 139, maxHeight: 40, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      radPowerBikes: file(relativePath: { eq: "68-rad-power-bikes.png" }) {
        childImageSharp {
          fluid(maxWidth: 115, maxHeight: 66, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <StackMediaBrandsSection data={data} />;
};

export default StackMediaBrands;
